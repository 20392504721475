<template>
  <div class="profile-container">
    <ProfileSidebar />
    <div class="page-container">
      <RouterView></RouterView>
    </div>
  </div>
</template>

<script>
import ProfileSidebar from '@/components/Menu/ProfileSidebar';
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'Profile',
  components: { ProfileSidebar },
  data: () => ({
    profile: '',
  }),
  async created() {
    if (this.currentPlanId === null) await this.getUserData();
    if (this.hasNoPlans) await this.getPlans();
  },
  computed: {
    ...mapState('profileGeneral', ['currentPlanId']),
    ...mapGetters('profileGeneral', ['hasNoPlans']),
  },
  methods: {
    ...mapActions('profileGeneral', ['getUserData', 'getPlans']),
  },
};
</script>

<style lang="scss" scoped>
.profile-container {
  width: 100%;
  height: 1000px;
  display: flex;
}
.page-container {
  flex-grow: 1;
  height: 100%;
}
</style>
